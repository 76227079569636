import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/headless-drupal",
  "date": "7th December 2023",
  "title": "Decoupled Applications - Headless Drupal",
  "summary": "What is Headless Drupal, why is it used, and how do we seamlessly manage it on the Skpr hosting platform?",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "Decoupled"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`What is Headless Drupal?`}</h3>
    <p>{`Headless Drupal is the approach of uncoupling the backend of the CMS system that manages content from the frontend that is responsible for presenting or displaying content, allowing Drupal to expose content to other frontend systems.`}</p>
    <p>{`The front-end is a separate application, typically built with a JavaScript framework such as React, Angular, or Vue.`}</p>
    <p>{`From an architecture perspective, each application is provisioned independently and managed by individual development teams.`}</p>
    <p>{`A common Headless Drupal topology consists of a single Drupal instance and multiple frontend applications connecting back to Drupal for their content.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/headless-drupal/architecture.jpg",
        "alt": "Overview of the architecture"
      }}></img></p>
    <h3>{`Why Headless Drupal?`}</h3>
    <p>{`The first question that might come to mind after reading about Headless Drupal is, why?`}</p>
    <p>{`Headless Drupal offers the advantages of autonomy.`}</p>
    <p><strong parentName="p">{`Content editors`}</strong>{` can focus on implementing a content architecture that works for them without worrying about how the data is presented.`}</p>
    <p><strong parentName="p">{`Developers`}</strong>{` can build with their preferred frontend tools for their specific project requirements.`}</p>
    <p><strong parentName="p">{`System administrators`}</strong>{` can scale out the infrastructure to hundreds of applications.`}</p>
    <h3>{`How does Skpr support Headless Drupal?`}</h3>
    <p>{`Headless Drupal demands a lot from the hosting solution.`}</p>
    <p>{`The hosting solution needs to deploy a mix of application types and provide simple solutions for managing the large number of application deployments running on the platform.`}</p>
    <p>{`That means the hosting platform has to provide the following:`}</p>
    <ul>
      <li parentName="ul">{`A CI/CD agnostic system for packaging and deploying applications so that separate development teams can use their preferred tools.`}</li>
      <li parentName="ul">{`A centralised monitoring interface so that developers and system administrators can see the health of the entire application portfolio at a glance.`}</li>
      <li parentName="ul">{`A robust configuration system that connects all the applications while being API-driven for ultimate flexibility.`}</li>
      <li parentName="ul">{`A system for tracking vulnerabilities across the extensive portfolio of applications deployed on the platform.`}</li>
      <li parentName="ul">{`A solution that scales with the number of applications provisioned and autoscaled.`}</li>
    </ul>
    <p>{`Anything less results in an exponential rise in manual intervention as more sites are provisioned on the hosting solution.`}</p>
    <p>{`Below, we outline how Skpr facilitates each of these requirements.`}</p>
    <h4>{`Packaging and Deployment`}</h4>
    <p>{`The Skpr hosting platform is built from the ground up using cloud-native technologies. One of these technologies is containers.`}</p>
    <p>{`Using containers, we can provide a packaging system that is`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Consistent`}</strong>{` and runs a set of repeatable steps inside a standard build environment.`}</li>
      <li parentName="ul"><strong parentName="li">{`Flexible`}</strong>{` and can be integrated into any CI/CD pipeline.`}</li>
    </ul>
    <p>{`Developers can package and deploy many application types using just a few commands.`}</p>
    <pre><code parentName="pre" {...{}}>{`skpr package 0.0.1
skpr deploy stg 0.0.1
skpr deploy prod 0.0.1
`}</code></pre>
    <p>{`The diagram below demonstrates how development teams can utilise their preferred CI/CD pipeline solution while still using our consistent package and deployment tooling.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/headless-drupal/deploy.jpg",
        "alt": "Overview of the architecture"
      }}></img></p>
    <h4>{`Centralised Monitoring`}</h4>
    <p>{`The Skpr hosting platform leverages AWS CloudWatch to aggregate metrics and logging data from the platform and the applications that run on top.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/headless-drupal/monitoring.jpg",
        "alt": "Overview of the architecture"
      }}></img></p>
    <p>{`Developers and system administrators can analyse the platform and their decoupled applications at multiple levels using our CloudWatch Dashboards.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Global`}</strong>{` - A high-level overview that outlines the system's health, e.g. Error rates, response times and resource utilisation across the entire hosting solution.`}</li>
      <li parentName="ul"><strong parentName="li">{`Application`}</strong>{` - A more granular version of the global dashboard, targeted towards a single application, e.g. dashboards provided for a project’s dev, staging and production environments.`}</li>
      <li parentName="ul"><strong parentName="li">{`AWS Managed Services`}</strong>{` - Dashboards that analyse the health of an individual AWS Managed Service, e.g. Amazon RDS, OpenSearch, EFS, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Security`}</strong>{` - Dashboards that provide an overview of security events and posture of the platform, e.g. WAF, Intrusion detection, etc.`}</li>
    </ul>
    <h4>{`Configuration`}</h4>
    <p>{`Our powerful configuration system lets you decouple config from code, update on the fly, and protect sensitive values from accidental exposure.`}</p>
    <p>{`For Drupal, this means making all the required information available for connecting to backend services, e.g. Database, Search, etc.`}</p>
    <p>{`In the context of our Headless Drupal, we can use this same system to also automatically provide all the front-end applications with the details they need to connect back to the Drupal content solution, e.g. Endpoint, Username/Password, etc. `}</p>
    <p>{`The Skpr configuration system can also be managed by an external system using our API for maximum flexibility.`}</p>
    <p>{`For more information, check out our blog post about architecting the `}<a parentName="p" {...{
        "href": "https://www.skpr.com.au/blog/architecting-skpr-configuration-system"
      }}>{`Skpr configuration system`}</a>{`.`}</p>
    <h4>{`Vulnerability Management`}</h4>
    <p>{`The Skpr hosting platform leverages Amazon Inspector for continual vulnerability management.`}</p>
    <p>{`Using Amazon Inspector means we can provide customers with a high-level overview of their hosting platform’s security posture and the applications deployed on top.`}</p>
    <p>{`Amazon Inspector scans the following layers of the Skpr platform:`}</p>
    <ul>
      <li parentName="ul">{`Application Images (ECR)`}</li>
      <li parentName="ul">{`EC2`}</li>
      <li parentName="ul">{`Lambda`}</li>
    </ul>
    <p>{`Customers can then determine the amount of security risk across all the components running as part of the decoupled application.`}</p>
    <h4>{`Autoscaling`}</h4>
    <p>{`We’ve configured Skpr with a highly scalable, immutable architecture backed by AWS Managed Services.`}</p>
    <p>{`The platform is designed to expand as the number of applications grows and demand for them increases. Every application is configured to autoscale based on the following key metrics:`}</p>
    <ul>
      <li parentName="ul">{`CPU`}</li>
      <li parentName="ul">{`Memory`}</li>
      <li parentName="ul">{`PHP Procs (For PHP-based applications only)`}</li>
    </ul>
    <p>{`Infrastructure components are either configured to autoscale (EC2 autoscaling groups), or, where applicable, provisioned with their serverless counterparts, allowing for rapid vertical autoscaling e.g. Aurora Serverless v2 for MySQL.`}</p>
    <p>{`As your decoupled application grows, so does Skpr.`}</p>
    <h3>{`Conclusion`}</h3>
    <p>{`Headless Drupal is a proven methodology that enables development teams to work autonomously. It also demands a flexible, mature hosting platform to manage this complex application architecture.`}</p>
    <p>{`The Skpr hosting platform is an excellent solution for hosting decoupled applications, given its mature systems for managing many applications at scale.`}</p>
    <p>{`Get in touch with us for more information on running your Decoupled or Headless Applications on Skpr.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      